import React from 'react';
import './index.scss';


export default function ModalBlock({ h1, text, children }) {

    return (
        <div className='modal-block'>
			<h1>{h1}</h1>
			<hr/>
			<p dangerouslySetInnerHTML={{ __html: text }}></p>			
			{children}
        </div>
    );
}