import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalWindow from '../../common/modalWindow';

export default function Form ( props ) {
	const navigate = useNavigate();

	const [isReg, setIsReg] = useState(true);
	const [login, setLogin] = useState('');
	const [password, setPassword] = useState('');
	const [errors, setErrors] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalText, setModalText] = useState('');

	const validate = () => {
		const newErrors = {};
		if (!login) {
			newErrors.login = 'Логин обязателен';
		} else if (login.length < 5) {
			newErrors.login = 'Логин должен содержать не менее 5 символов';
		}

		if (!password) {
			newErrors.password = 'Пароль обязателен';
		} else if (password.length < 6) {
			newErrors.password = 'Пароль должен содержать не менее 6 символов';
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleSubmitReg = async (event) => {
		event.preventDefault();
		if (validate()) {
			setIsSubmitting(true);
			const response = await fetch('/server/http/register.php', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: JSON.stringify({ login: login, password: password }), 
			});
			const result = await response.json();
			if (result.status === 'error') {
				setModalText(result.message);
				setIsModalOpen(true);
			}
			else{
				navigate('/main-menu', {
					state: {
						onlineUsers: result.online_users,
						invites: result.invites,
					},
				});
			} 
			setIsSubmitting(false);
		}
	};

	const handleSubmitInput = async (event) => {
		event.preventDefault();
		if (validate()) {
			setIsSubmitting(true);
			const response = await fetch('/server/http/input.php', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: JSON.stringify({ login: login, password: password }), 
			});
			const result = await response.json();
			if (result.status === 'error') {
				setModalText(result.message);
				setIsModalOpen(true);
			}
			else{
				navigate('/main-menu', {
					state: {
						onlineUsers: result.online_users,
						invites: result.invites,
					},
				});
			}
			setIsSubmitting(false);
		}
	};

	return (
		<>
			<form>
				<h1>{isReg?'Регистрация':'Вход'}</h1>

				<div>
					<label htmlFor="login">Логин</label>
					<input
						id="login"
						value={login}
						onChange={(e) => {
						setLogin(e.target.value);
						setErrors((prevErrors) => ({ ...prevErrors, login: undefined })); 
						}}
					/>
					<span className='error'>{errors.login}</span>
				</div>

				<div>
					<label htmlFor="password">Пароль</label>
					<input
						id="password"
						type="password"
						value={password}
						onChange={(e) => {
						setPassword(e.target.value);
						setErrors((prevErrors) => ({ ...prevErrors, password: undefined })); 
						}}
					/>
					<span className='error'>{errors.password}</span>
				</div>

				<button type="submit" disabled={isSubmitting} onClick={isReg?handleSubmitReg:handleSubmitInput} className='primary-button'>
				{isReg?(isSubmitting ? 'Регистрация...' : 'Зарегистрироваться'):(isSubmitting ? 'Вход...' : 'Войти')}
				</button>

				<p>
					{isReg ? (
						<>
							Есть аккаунт? <button onClick={(event) => {event.preventDefault(); setIsReg(false)}}>Войдите</button>
						</>
					) : (
						<>
							Нет аккаунта? <button onClick={(event) => {event.preventDefault(); setIsReg(true)}}>Зарегистрируйтесь</button>
						</>
					)}
				</p>

			</form>
			{isModalOpen && <ModalWindow onClose={() => setIsModalOpen(false)} text={modalText}/>}
		</>

	);
}
