import React from "react";
import './style.scss';
import ModalBlock from "../../common/modalBlock";

export default function Admin () {

	return (
		<section className="admin">

			<ModalBlock h1={'Админ панель'} text={'Список кнопок для управления базой данных ниже'}>
				<button className="primary-button">Удалить все неактивные игры</button>
				<button className="primary-button">Удалить всех пользователей</button>
				<button className="primary-button">Удалить все неактивные комнаты</button>
				<button className="primary-button">Добавить тестовых юзеров</button>
			</ModalBlock>
		</section>
	)
}