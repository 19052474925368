import React, { useEffect, useState, useRef } from "react";
import { ReactSVG } from "react-svg";



export default function SVGScheme ({cells, playerChipsOnField, setPlayerChipsOnField, chipsOnField, userDragableChip}) {

	const letterScores = {
        А: 1, И: 1, Р: 2, Ш: 10, Б: 3, Й: 2, С: 2, Щ: 10,
        В: 2, К: 2, Т: 2, Ъ: 10, Г: 3, Л: 2, У: 3, Ы: 5,
        Д: 2, М: 2, Ф: 10, Ь: 5, Е: 1, Н: 1, Х: 5, Э: 10,
        Ж: 5, О: 1, Ц: 10, Ю: 10, З: 5, П: 2, Ч: 5, Я: 3
    };


	const handleSVGInjection = (svg) => {
		const svgElement = svg.querySelector('g');
		if (svgElement) {
			cells.forEach((element) => {
				const currentCell = (svgElement.querySelector(`path[data-id="cell-${(element.y - 1) * 15 + element.x}"]`));
				currentCell.classList = element.type;
			})


			if (playerChipsOnField && playerChipsOnField.length > 0)
			{
				playerChipsOnField.forEach ((element) => {
					const currentCell = (svgElement.querySelector(`path[data-id="cell-${(element.y - 1) * 15 + element.x}"]`));
					const bbox = currentCell.getBBox();
		
					const groupElement = document.createElementNS('http://www.w3.org/2000/svg', 'g');
					groupElement.setAttribute('class', 'player-chip-container');
					groupElement.setAttribute('transform', `translate(${bbox.x}, ${bbox.y})`);
		
					const textElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
					textElement.setAttribute('x', bbox.width / 2);
					textElement.setAttribute('y', bbox.height / 2 + 2);
					textElement.setAttribute('class', 'player-chip-on-field');
					textElement.textContent = element.value;
					textElement.setAttribute('draggable', `true`);
		
					const chipScore = document.createElementNS('http://www.w3.org/2000/svg', 'text');
					chipScore.setAttribute('x', bbox.width - 5); 
					chipScore.setAttribute('y', 2); 
					chipScore.setAttribute('class', 'player-chip-score');
					chipScore.textContent = letterScores[element.value];
		
					const backgroundRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
					backgroundRect.setAttribute('width', bbox.width);
					backgroundRect.setAttribute('height', bbox.height);
		
					groupElement.appendChild(backgroundRect);
					groupElement.appendChild(textElement);
					groupElement.appendChild(chipScore);
		
					svgElement.appendChild(groupElement);		
		
					groupElement.addEventListener('click', () => {
						setPlayerChipsOnField((prevChips) => {
							const updatedChips = prevChips.filter((chip) => chip.id !== element.id);
							return updatedChips;
						});
					});	
				})

			}

			if (userDragableChip && userDragableChip.x)
			{
				
				const currentCell = (svgElement.querySelector(`path[data-id="cell-${(userDragableChip.y - 1) * 15 + userDragableChip.x}"]`));
				const bbox = currentCell.getBBox();
	
				const groupElement = document.createElementNS('http://www.w3.org/2000/svg', 'g');
				groupElement.setAttribute('class', 'player-chip-container');
				groupElement.setAttribute('transform', `translate(${bbox.x}, ${bbox.y})`);
	
				const textElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
				textElement.setAttribute('x', bbox.width / 2);
				textElement.setAttribute('y', bbox.height / 2 + 2);
				textElement.setAttribute('class', 'player-chip-on-field');
				textElement.textContent = userDragableChip.value;
				textElement.setAttribute('draggable', `true`);
	
				const chipScore = document.createElementNS('http://www.w3.org/2000/svg', 'text');
				chipScore.setAttribute('x', bbox.width - 5); 
				chipScore.setAttribute('y', 2); 
				chipScore.setAttribute('class', 'player-chip-score');
				chipScore.textContent = letterScores[userDragableChip.value];
	
				const backgroundRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
				backgroundRect.setAttribute('width', bbox.width);
				backgroundRect.setAttribute('height', bbox.height);
	
				groupElement.appendChild(backgroundRect);
				groupElement.appendChild(textElement);
				groupElement.appendChild(chipScore);
	
				svgElement.appendChild(groupElement);		
			}

			if (chipsOnField && chipsOnField.length > 0)
			{
				svgElement.querySelector(`path[data-id="cell-${(7) * 15 + 8}"]`).style.fill = "#a3aead";

				chipsOnField.forEach ((element) => {
					const currentCell = (svgElement.querySelector(`path[data-id="cell-${(element.y - 1) * 15 + element.x}"]`));
					const bbox = currentCell.getBBox();
		
					const groupElement = document.createElementNS('http://www.w3.org/2000/svg', 'g');
					groupElement.setAttribute('class', 'old-chip-container');
					groupElement.setAttribute('transform', `translate(${bbox.x}, ${bbox.y})`);
		
					const textElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
					textElement.setAttribute('x', bbox.width / 2);
					textElement.setAttribute('y', bbox.height / 2 + 2);
					textElement.setAttribute('class', 'old-chip-on-field');
					textElement.textContent = element.value;
					textElement.setAttribute('draggable', `true`);
		
					const chipScore = document.createElementNS('http://www.w3.org/2000/svg', 'text');
					chipScore.setAttribute('x', bbox.width - 5); 
					chipScore.setAttribute('y', 2); 
					chipScore.setAttribute('class', 'old-chip-score');
					chipScore.textContent = letterScores[element.value];
		
					const backgroundRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
					backgroundRect.setAttribute('width', bbox.width);
					backgroundRect.setAttribute('height', bbox.height);
		
					groupElement.appendChild(backgroundRect);
					groupElement.appendChild(textElement);
					groupElement.appendChild(chipScore);
		
					svgElement.appendChild(groupElement);
				}
				)
				
			}
		}
	}

	return (
		<ReactSVG src="/field.svg" className="field" afterInjection={handleSVGInjection}/>
	)
}
