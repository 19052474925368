import React, {useEffect} from 'react';
import './index.scss';


export default function ModalWindow({ text, onClose, children, isBigWidth, maxWidth = '300px' }) {
	useEffect(() => {
        document.body.classList.add('no-scroll');

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, []);

    return (
        <div className={`modal ${React.Children.count(children) === 0 ? 'modal-empty ' : ''}${isBigWidth ? 'big-width': ''}`} onClick={onClose}>
            <div onClick={(e) => e.stopPropagation()}   style={{ maxWidth: maxWidth }}>
                <p>{text}</p>
                {children}
            </div>
			<button onClick={onClose}><img src="/cross.svg" alt="Закрыть"/></button>
        </div>
    );
}