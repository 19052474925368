export default function pluralNumber(number, singular, plural, genitivePlural) {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastDigit === 1 && lastTwoDigits !== 11) {
        return `${number} ${singular}`;
    }

    if (lastDigit >= 2 && lastDigit <= 4 && (lastTwoDigits < 10 || lastTwoDigits >= 20)) {
        return `${number} ${plural}`;
    }

    return `${number} ${genitivePlural}`;
}
