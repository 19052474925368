import React, { useEffect, useState, useRef } from "react";
import ModalWindow from "../../common/modalWindow";
import ModalBlock from "../../common/modalBlock";
import UserLi from "../../common/userLi";
import { useNavigate, useLocation } from "react-router-dom";
import './style.scss';

export default function MainMenu () {
    const navigate = useNavigate();
    const location = useLocation();
	const initialOnlineUsers = location.state?.onlineUsers || [];
	const initialInvites = location.state?.invites || {};
    const token = document.cookie.split('; ').find(row => row.startsWith('player_token='))?.split('=')[1];
    const login = decodeURIComponent(document.cookie.split('; ').find(row => row.startsWith('player_login='))?.split('=')[1]);

    const [onlineUsers, setOnlineUsers] = useState(initialOnlineUsers);
	const [invites, setInvites] = useState(initialInvites);
    const [isSendCreateRoom, setIsSendCreateRoom] = useState(false);
    const [modalType, setModalType] = useState('');
    const [modalText, setModalText] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [numberOfPlayers, setNumberOfPlayers] = useState(0);

    const socketRef = useRef(null);

    const numberOfPlayersRef = useRef(numberOfPlayers);
    useEffect(() => {
        numberOfPlayersRef.current = numberOfPlayers;
    }, [numberOfPlayers]);


    useEffect(() => {
        if (!token) {
            navigate('/');
            return;
        }
    
        let reconnectAttempts = 0;
        const maxReconnectAttempts = 10;
        let isReconnecting = false;
    
        const connectSocket = () => {
            try {
                socketRef.current = new WebSocket(`wss://scrabble.gentofsite.ru:8080/?token=${token}`);
            } catch {
                handleReconnectError('Ошибка при попытке установить соединение. Попробуйте обновить страницу.');
                return;
            }
    
            const socket = socketRef.current;
    
            if (socket) {
                socket.onopen = () => {
                    console.log('Соединение установлено');
                    reconnectAttempts = 0; 
                };
    
                socket.onmessage = (event) => {
                    const data = JSON.parse(event.data);
                    if (data) {
                        if (data.status && data.status === 'error') {
                            setModalType('error');
                            setModalText(data.message);
                            setIsModalOpen(true);
                        }
                        if (data.online_users) {
                            setOnlineUsers(data.online_users);
                        }
                        if (data.invites) {
                            setInvites(data.invites);
                        }
                        if (data.create_room) {
                            setIsSendCreateRoom(false);
                            const roomInfo = JSON.parse(data.create_room);
                            if (roomInfo.status === 'success') {
                                navigate(`/room/?room_token=${roomInfo.token_room}`, {
                                    state: {
                                        maxNumberOfPlayer: numberOfPlayersRef.current,
                                        onlineUsers: roomInfo.online_users,
                                        usersInRoom: [login],
                                    },
                                });
                            }
                        }
                        if (data.room_token) {
                            if (data.room_info && data.room_info.status === 'error') {
                                setModalType('error');
                                setModalText(data.room_info.message);
                                setIsModalOpen(true);
                            } else {
                                console.log(data);
                                navigate('/room/?room_token=' + data.room_token, {
                                    state: {
                                        onlineUsers: data.room_info.users_online_list,
                                        invitedPlayers: data.room_info.invited_players,
                                        usersInRoom: data.room_info.users_in_room,
                                        maxNumberOfPlayer: data.room_info.max_players,
                                    },
                                });
                            }
                        }
                    }
                };
    
                socket.onerror = (error) => {
                    console.error('WebSocket error:', error);
                    handleReconnectError('Ошибка при попытке установить соединение. Попробуйте обновить страницу.');
                };
    
                socket.onclose = () => {
                    console.log('Соединение закрыто');
                    handleReconnectError('Не удалось восстановить соединение. Попробуйте перезагрузить страницу.');
                };
            }
        };
    
        const handleReconnectError = (message) => {
            if (!isReconnecting && (reconnectAttempts < maxReconnectAttempts)) {
                isReconnecting = true;
                reconnectAttempts++;
                console.log(`Попытка переподключения (${reconnectAttempts}/${maxReconnectAttempts})...`);
                setTimeout(() => {
                    connectSocket();
                    isReconnecting = false;
                }, 5000); 
            } else if (reconnectAttempts >= maxReconnectAttempts) {
                isReconnecting = false;
                setModalType('error');
                setModalText(message);
                setIsModalOpen(true);
            }
        };
    
        connectSocket();
    
        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    }, [token, navigate]);

    const sendMessage = (message) => {
        if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
            socketRef.current.send(JSON.stringify(message));
        }
    };

    const formCreateRoom = (event) => {
        event.preventDefault();
        setModalType('createRoom');
        setIsModalOpen(true);
    };

    const createRoom = (event) => {
        event.preventDefault();
        setIsSendCreateRoom(true);
        const roomData = {
            type: 'createRoom',
            payload: {
                maxPlayers: numberOfPlayers
            },
        };
        sendMessage(roomData);
        setIsSendCreateRoom(false);
    };

    const acceptInvite = (event) => {
        if (event.target.tagName.toLowerCase() === 'button') {
            event.target.disabled = true;
            event.preventDefault();
            const roomData = {
                type: 'acceptInvite',
                payload: {
                    roomToken: event.target.dataset.token
                },
            };
            sendMessage(roomData);
        }
    }

	return (
        <>
		<section className="main-menu">
            <ModalBlock h1='Главное меню' text = {`Добро пожаловать, ${login}. Здесь вы можете начать новую игру или принять приглашение от другого игрока. <a href="https://youtu.be/4ANTOzUUQyY?si=8qAxcjsPm9TTOfRb">Туториал</a>`}>
                <button className="primary-button" onClick={formCreateRoom}>Создать свою комнату</button>
            </ModalBlock>
            <ul>
                <li>
                    <ol>
                        <h2>Список онлайн пользователей</h2>
                        {(onlineUsers && onlineUsers.length > 0) ? onlineUsers.map((element, index) => (
                            <UserLi userName={element} pseudoContent={index + 1} key={index}/>
                        )):
                        <p>Нет онлайн пользователей</p>}
                    </ol>
                </li>
                <li>
                    <ul onClick={acceptInvite}>
                        <h2>Список приглашений</h2>
                        {(invites && invites.length > 0) ? invites.map((element, index) => (
                            <UserLi 
                                userName={element.login_inviter} 
                                isAfter={true} 
                                pseudoContent={""}
                                isButton={true}
                                svgAfter = '/arrow.svg'
                                colors={[
                                    '#FFFFFF',  
                                    '#3C87E3',   
                                    '#CA564C',   
                                    '#FFFFFF'    
                                ]}
                                key={index}
                                dataToken={element.token_room}
                            />
                        )):
                        <p>Нет приглашений</p> }
                    </ul>
                </li>
            </ul>
		</section>
        {isModalOpen && (modalType === 'createRoom' ? <ModalWindow 
            type='radio'
            text={'Выберите число игроков'}
            onClose={() => {!isSendCreateRoom && (setIsModalOpen(false) || setNumberOfPlayers (0))}}
        >
            <form className="number-of-players" onSubmit={createRoom}>
                <label><input type="radio" name="number-of-players" value={2} onClick={() => {!isSendCreateRoom && setNumberOfPlayers (2)}}></input>2</label>
                <label><input type="radio" name="number-of-players" value={3} onClick={() => {!isSendCreateRoom && setNumberOfPlayers (3)}}></input>3</label>
                <label><input type="radio" name="number-of-players" value={4} onClick={() => {!isSendCreateRoom && setNumberOfPlayers (4)}}></input>4</label>
                <label><input type="radio" name="number-of-players" value={5} onClick={() => {!isSendCreateRoom && setNumberOfPlayers (5)}}></input>5</label>
                <button type="submit" className="primary-button" disabled={numberOfPlayers === 0 || isSendCreateRoom}>Создать комнату</button>
            </form>

        </ModalWindow> : 
        <ModalWindow text={modalText}  onClose={() => {setIsModalOpen(false)}}>
        </ModalWindow>
        )}
        </>

	)
}
