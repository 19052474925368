import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import ModalWindow from "../../common/modalWindow";
import pluralNumber from "../../helpers/pluralNumber"
import ModalBlock from "../../common/modalBlock";
import UserLi from "../../common/userLi";

import './style.scss';

export default function Room () {
    const navigate = useNavigate();
    const location = useLocation();
    const urlParams = new URLSearchParams(window.location.search);
    const roomToken = urlParams.get('room_token');

    const initialOnlineUsers = location.state?.onlineUsers || [];
	const initialInvitedPlayers = location.state?.invitedPlayers || [];
    const initialUsersInRoom = location.state?.usersInRoom || [];
	const initialMaxNumberOfPlayers = location.state?.maxNumberOfPlayer || 0;
    

    const token = document.cookie.split('; ').find(row => row.startsWith('player_token='))?.split('=')[1];
    const login = decodeURIComponent(document.cookie.split('; ').find(row => row.startsWith('player_login='))?.split('=')[1]);
    
    const [maxNumberOfPlayers, setMaxNumberOfPlayers] = useState(initialMaxNumberOfPlayers);
    const [onlineUsers, setOnlineUsers] = useState(initialOnlineUsers);
    const [players, setPlayers] = useState(initialUsersInRoom);
    const [invitedPlayers, setInvitedPlayers] = useState(initialInvitedPlayers);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalText, setModalText] = useState('');

    const socketRef = useRef(null);

    let reconnectAttempts = 0;
    const maxReconnectAttempts = 10;
    let isReconnecting = false;

    useEffect(() => {

        if (!token) {
            navigate('/');
            return;
        }

        const connectSocket = () => 
        {
            try{
                socketRef.current = new WebSocket(`wss://scrabble.gentofsite.ru:8080/room/?token=${token}&room_token=${roomToken}`);
            }
            catch{
                handleReconnectError('Ошибка при попытке установить соединение. Попробуйте обновить страницу.');
                return;
            }
    
            const socket = socketRef.current;
    
            if (socket)
            {
                socket.onopen = () => {
                    console.log('Соединение установлено');
                    reconnectAttempts = 0; 
                };
    
                socket.onmessage = (event) => {
                    const data = JSON.parse(event.data);
                    if (data) {
                        if (data.status && data.status == 'error'){
                            setIsModalOpen(true);
                            if (data.message.trim() != '') setModalText(data.message);
                        }
                        if (data.online_users) 
                            setOnlineUsers(data.online_users);
                        if (data.users_in_room) 
                            setPlayers(data.users_in_room);
                        if (data.max_number_of_players)
                            setMaxNumberOfPlayers(data.max_number_of_players);
                        if (data.invited_players)
                            setInvitedPlayers(data.invited_players);
                        if (data.game_status && data.game_status=='started')
                            navigate(`/game?room_token=${roomToken}`);
                    }
                };
    
                socket.onerror = (error) => {
                    console.error('WebSocket error:', error);
                    handleReconnectError('Ошибка при попытке установить соединение. Попробуйте обновить страницу.');
                };
    
                socket.onclose = () => {
                    console.log('Соединение закрыто');
                    handleReconnectError('Не удалось восстановить соединение. Попробуйте перезагрузить страницу.');
                };
            }
        }


        const handleReconnectError = (message) => {
            if (!isReconnecting && (reconnectAttempts < maxReconnectAttempts)) {
                isReconnecting = true;
                reconnectAttempts++;
                console.log(`Попытка переподключения (${reconnectAttempts}/${maxReconnectAttempts})...`);
                setTimeout(() => {
                    connectSocket();
                    isReconnecting = false;
                }, 5000); 
            } else if (reconnectAttempts >= maxReconnectAttempts) {
                isReconnecting = false;
                setModalText(message);
                setIsModalOpen(true);
            }
        };

        connectSocket();

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
        };

    }, [token, roomToken, navigate]);



    const sendMessage = (message) => {
        if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
            socketRef.current.send(JSON.stringify(message));
        }
    };


    const invitePlayer = (event) => {
        if (event.target.tagName.toLowerCase() === 'button') {
            setInvitedPlayers((prevInvitedPlayers) => {
                  return [...prevInvitedPlayers, event.target.textContent];
            })
            const inviteData = {
                type: 'invitePlayer',
                payload: {
                    invitedPlayer: event.target.innerHTML
                },
            };
            sendMessage(inviteData);
        }
    };

	return (
        <>
			<section className="room">
				<ModalBlock h1='Ожидание игроков' text = {`Игра начнется, когда наберется ${pluralNumber(maxNumberOfPlayers, 'игрок', 'игрока', 'игроков')}. Вы можете пригласить кого-то, вернуться в главное меню или просто подождать`}>
                    <Link to="/main-menu" className="primary-button">Вернуться в главное меню</Link>				
                </ModalBlock>
				<ul>
					<li>
						<ol>
							<h2>Список игроков в&nbsp;комнате</h2>
							{(players && players.length > 0) ? players.map((element, index) => (
								<UserLi userName={element} pseudoContent={index + 1} key={index}/>
							)):
							<p>Нет игроков</p>}
						</ol>
					</li>
					<li>
						<ul onClick={invitePlayer}>
							<h2>Список онлайн пользователей</h2>
							{(onlineUsers && onlineUsers.length > 0) ? onlineUsers.map((element, index) => {
								const isDisabled = invitedPlayers && invitedPlayers.includes(element) || element==login;
                                return (
                                    <UserLi 
                                        userName={element} 
                                        isAfter={true} 
                                        pseudoContent={""}
                                        isButton={true}
                                        svgAfter='/arrow.svg'
                                        colors = { isDisabled ? 
                                            [
                                                '#FFFFFF',  
                                                '#3C87E3',   
                                                '#A3AEAD',   
                                                '#FFFFFF'    
                                            ]:
                                            [
                                                '#FFFFFF',  
                                                '#3C87E3',   
                                                '#CA564C',   
                                                '#FFFFFF'    
                                            ]
                                        }
                                        isDisabled={isDisabled} 
                                        key={index}
                                    />
                                );
							}):
							<p>Нет пользователей</p> }
						</ul>
					</li>
				</ul>
			</section>
            {isModalOpen && <ModalWindow text={modalText}  onClose={() => {setIsModalOpen(false)}}></ModalWindow>}
        </>

	)
}
