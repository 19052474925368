import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Authorization from './Pages/Authorization';
import MainMenu from './Pages/MainMenu';
import Room from './Pages/Room';
import Game from './Pages/Game';
import Admin from './Pages/Admin';

function App() {
	return (
	  <Router>
		<Routes>
			<Route path="/" element={<Authorization />} />
			<Route path="/main-menu" element={<MainMenu />} />
			<Route path="/room" element={<Room />} />
			<Route path="/game" element={<Game />} />
			<Route path="/admin" element={<Admin />} />
		</Routes>
	  </Router>
	);
  }
export default App;
